@media only screen and (min-width: 768px) {
  .navbar-fixed-top {
    min-height: 216px;
    width: 100%;
    border: 0;
    border-radius: 0;
    background: white;
    transition: ease all 0.4s;

    &:before {
      content: "";
      width: 100%;
      height: 10px;
      background: url('/images/bg-line.jpg') repeat-y;
      background-position: center;
      background-size: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      overflow: hidden;
      transition: ease all 0.25s;
    }

    .brand {
      margin-top: 40px;
      float: left;
      max-width: 375px;
      transition: ease all 0.15s;
      img {
        max-width: 100%;
      }
    }

    button.menu-toggle {
      display:none;
    }

    #navbar {
      .navbar-right {
        margin-top: 106px;
        transition: ease all 0.15s;
        li {
          a {
            padding: 0 0 10px 0;
            margin: 0 18px;
            background: transparent;
            position: relative;
            font-family: $font-primary;
            font-weight: 600;
            font-size: 20px;

            &:before {
              content: "";
              width: 0;
              height: 4px;
              background: $primary-color;
              position: absolute;
              left: 0;
              right: 0;
              bottom: 0;
              margin: 0 auto;
              transition: ease all 0.25s;
            }
            &:hover,
            &:focus {
              color: $primary-color;

              &:before {
                width: 100%;
              }
            }
          }
          &:last-child {
            margin-right: 0;
          }
          &.active {
            a {
              color: $primary-color;
              &:before {
                content: "";
                width: 100%;
                height: 4px;
                background: $primary-color;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
              }
            }
          }
        }
      }
    }
    &.smaller {
      min-height: 140px;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.075);
      .brand {
        max-width: 220px;
        margin-top: 32px;
        height: auto !important;
        img {
          height: auto !important;
        }
      }
      #navbar {
        .navbar-right {
          margin-top: 62px;
        }
      }
      &:before {
        height: 5px;
      }
    }
  }
}