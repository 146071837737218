#contact {
  #contact-block {
    color:$primary-color;
    font-size:17px;
    line-height:32px;
    padding-bottom:0;
    h2 {
      font-weight:700;
    }
    small {
      font-size:75%;
    }
    .info {
      color:$font-color;
      font-family: $font-primary;
      font-weight:300;
      line-height:32px;
    }
  }
  .contact-info {
    margin:0;
    padding:0;
    list-style:none;
    li {
      float:left;
      width:100%;
      padding:0 0 10px 0;
      a {
        display:inline-block;
        position:relative;
        font-size:24px;
        &:hover,
        &:focus {
          text-decoration:none;
          color:$secondary-color;
        }
      }
      &.email {
        a {
          padding-left:40px;
          &:before {
            content:"\f0e0";
            position:absolute;
            left:0;
            top:0;
            font-family: FontAwesome;
          }
        }
      }
      &.phone {
        a {
          padding-left:40px;
          &:before {
            content:"\f095";
            position:absolute;
            left:3px;
            top:0;
            font-family: FontAwesome;
          }
        }
      }
    }
  }
  .socials {
    margin:20px 0 0 0;
    padding:0;
    display:block;
    float:left;
    width:100%;
    list-style: none;
    li {
      float:left;

      a {
        margin:0 3px;
        i {
          font-size:20px;
        }
        &:hover,
        &:focus {
          color:$secondary-color;
        }
      }
      &:first-child {
        padding-right:3px;
        color:$font-color;
        font-weight:300;
      }
    }
  }
}

#extra_info {
  h3 {
    font-size:20px;
  }
}