#opdrachtgevers {
  background:$grey-light;
  width:100%;
  padding:60px 0 30px 0;
  position:relative;
  z-index:30;

  .btn-logo.no-url {
    &:hover,
    &:focus {
      span.title {
        h3 {
          text-decoration:none;
          bottom:18px;
        }
        &:before {
          opacity:0;
          bottom:0;
        }
        &:after {
          opacity:0;
          height:0;
        }
      }
    }
  }
}