.btn-logo {
  margin:0 0 30px 0;
  display:block;
  float:left;
  width:100%;
  text-decoration: none;
  position:relative;
  background:white;
  border:1px solid #eee;
  span.title {
    color:$primary-color;
    display:block;
    padding:0;
    width:100%;
    height:50px;
    text-decoration: none;
    position:relative;
    z-index:1;
    h3 {
      font-size:14px;
      font-family: $font-primary;
      font-weight:300;
      line-height:20px;
      margin:0;
      padding:0 40px 0 0;
      text-transform: uppercase;
      text-decoration:none;
      position:absolute;
      bottom:18px;
      left:20px;
      transition:ease all 0.3s;
    }
    &:before {
      content:"\f061";
      font-family: FontAwesome;
      position:absolute;
      bottom:15px;
      right:15px;
      z-index:20;
      color:$primary-color;
      font-size:18px;
      opacity:0;
      transition:ease all 0.3s;
    }
    &:after {
      content:"";
      width:100%;
      height:0;
      bottom:0;
      left:0;
      background:url('/images/bg-line.jpg') no-repeat;
      background-size:100% 7px;
      position:absolute;
      opacity:0;
      z-index:20;
      transition:ease all 0.3s;
    }
  }
  span.image {
    display:block;
    width:100%;
    height:200px;
    text-align:center;
    margin:0 auto;
    position:relative;
    img {
      max-width:80%;
      max-height:160px;
      position:absolute;
      top:50%;
      left:50%;
      transform:translate(-50%,-50%);
    }
  }
  &:hover,
  &:focus {
    text-decoration:none;
    span.title {
      text-decoration:none;
      h3 {
        text-decoration:none;
        bottom:25px;
      }
      &:before {
        opacity:1;
        bottom:24px;
      }
      &:after {
        opacity:1;
        height:7px;
      }
    }
    span.image {
    }
  }
}