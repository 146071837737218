html, body {
  height: 100%;
  background: white;
  padding: 0;
  margin: 0;
  font-size: 100%;
}
html {
  height: 100%;
}
body {
  height: auto !important;
  height: 100%;
  position: relative;
  min-height: 100%;
  overflow-x: hidden;
  font-family: $font-primary;
  color:$font-color;
  font-size:16px;
  padding:216px 0 0 0;
  margin: 0;
  &:before,
  &:after {
    content:"";
    position:fixed;
    width:233px;
    height:354px;
    z-index:0;
    opacity:0.2;
  }
  &:before {
    background:url('/images/puzzel-grey.png') no-repeat;
    background-size:233px 354px;
    left:-116px;
    bottom:100px;
  }
  &:after {
    background:url('/images/puzzel-grey.png') no-repeat;
    background-size:233px 354px;
    right:-116px;
    top:300px;
    transform:rotate(-180deg);
  }
}

a {
  color:$primary-color;
  outline:0;
  &:hover,
  &:focus {
    outline:0;
  }
}

.column {
  position:relative;
  float:left;
  height:244px;
  padding:10px;
  .btn-block {
    width:100%;
    float:left;
    position:relative;
    background:black;
    height:100%;
    display:block;
    color:$primary-color;
    font-size:27px;
    font-weight:600;
    text-decoration:none;
    overflow:hidden;
    transition: ease all 0.3s;
    span.image {
      width:100%;
      height:100%;
      display:block;
      position:absolute;
      top:0;
      left:0;
      z-index:1;
      filter: blur(0px);
      transition: ease all 0.3s;
      &:after {
        content:"";
        position:absolute;
        top:0;
        left:0;
        opacity:0.75;
        width:100%;
        height:100%;
        background: -moz-linear-gradient(-45deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(-45deg, rgba(0,0,0,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(135deg, rgba(0,0,0,1) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
        z-index:100;
      }
    }
    span.title {
      z-index:15;
      position:relative;
      display:block;
      padding:20px 25px;
      color:$primary-color;
    }
    &:before {
      content:"";
      width:100%;
      height:100%;
      position:absolute;
      z-index:10;
      transition:ease all 0.25s;
      background:rgba(68,185,198,0);
    }
    &:after {
      content:"\f054";
      font-family: FontAwesome;
      position:absolute;
      z-index:2;
      bottom:5px;
      right:5px;
      font-size:14px;
      color:black;
      background:$primary-color;
      width:20px;
      height:20px;
      padding-left:6px;
      padding-top:1px;
    }
    &:hover {
      &:before {
        width:100%;
        height:100%;
        background:rgba(68,185,198,0.2);
      }
      span.image {
        width:104% !important;
        height:104% !important;
        left:-2%;
        top:-2%;
        filter: blur(2px);
      }
    }
  }
  .quote {
    width:100%;
    float:left;
    position:relative;
    background:black;
    height:100%;
    display:block;
    z-index:100;
    span {
      position:absolute;
      top:50%;
      left:50%;
      text-align:center;
      transform:translate(-50%,-50%);
      font-size:27px;
      font-weight:600;
      font-family: $font-primary;
      width:100%;
      padding:9px 15px;
      &:before {
        content:"";
        position:absolute;
        left:0;
        right:0;
        top:0;
        margin:0 auto;
        width:155px;
        height:100%;
        border-top:1px solid white;
        border-bottom:1px solid white;
      }
      &:after {
        content:"";
        top:-13px;
        left:0;
        right:40%;
        margin:0 auto;
        background:url('/images/quote.png') no-repeat;
        background-size:31px 21px;
        position:absolute;
        width:31px;
        height:21px;
      }
    }
  }
  .quote-lg {
    span {
      &:before {
        width:236px;
      }
      &:after {
        right:30%;
      }
    }
  }
  .blue-block {
    background:$primary-color url('/images/bg-quote-blue.png') repeat;
    color:white;
  }
  .grey-block {
    background:$primary-color url('/images/bg-quote-grey.jpg') repeat;
    color:white;
  }
  .green-block {
    background:$primary-color url('/images/bg-quote-green.jpg') repeat;
    color:white;
  }
  .green-light-block {
    background:$primary-color url('/images/bg-quote-green-light.jpg') repeat;
    color:white;
  }
}
.column-small {
  width:35%;
}
.column-medium {
  width:44%;
}
.column-large {
  width:58%;
}
.column-square {
  width:21%;
}
.column-half {
  width:50%;
}

.content-block {
  padding:60px 0 30px 0;
  h1, h2, h3, h4, h5, h6 {
    color:$primary-color;
    font-weight:700;
    margin:0 0 4px 0;
    padding:0;
  }
  p {
    font-family: $font-primary;
  }
  img {
    max-width:100%;
    height:auto !important;
    display:block;
    margin:15px 0;
  }
  .introduction {
    color:$primary-color;
    font-weight:700;
    display:block;
    margin:0 0 32px 0;
    p, ul, li, ol {
      line-height:32px;
      font-size:17px;
    }
  }
  .content {
    p, ul, li, ol {
      color:$font-color;
      font-weight:300;
      line-height:32px;
      font-size:17px;
    }
    p, ul, ol {
      margin:0 0 32px 0;
    }
    a {
      color:$primary-color;
    }
    ul {
      list-style:none;
      padding:0;
      li {
        position:relative;
        margin:0;
        padding:2px 0 2px 38px;
        &:before {
          content:"";
          position:absolute;
          left:0;
          top:7px;
          background:url('/images/peace.png') no-repeat;
          background-size:24px 21px;
          width:24px;
          height:21px;
        }
      }
    }
  }
}