@media only screen and (max-width: 767px) {
  body {
    padding-top:90px;
    &:before,
    &:after {
      opacity:0.1;
    }
  }
  .column-half,
  .column-small,
  .column-medium,
  .column-large,
  .column-square {
    width:100%;
  }
  #home-blocks {
    height:auto;
    padding:10px 0;
  }
  .navbar-fixed-top {
    min-height:90px;
    margin:0;
    top:0;
    border:0;
    .brand {
      float:left;
      max-width:150px;
      margin:15px 10px 0 15px;
      height:auto !important;
      img {
        max-width:100%;
        display:block;
        height:auto !important;
      }
    }
    #navbar {
      border:0;
      border-color:transparent;
      .navbar-right {
        margin-top:15px;
        margin-bottom:0;
        border:0;
        li {
          a {
            border-bottom:1px solid #fff;
            padding:17px 10px;
            text-align:center;
            color:$font-color;
            font-weight:700;
            font-size:21px;
          }
          &.active {
            a {
              color:$primary-color;
            }
          }
        }
      }
    }
    &.smaller {
      min-height:90px;
      margin:0;
      top:0;
      border:0;
      box-shadow:2px 2px 2px rgba(0,0,0,0.2);
      .brand {
        max-width:150px;
        img {
          height: auto !important;
        }
      }
    }
  }

  button.menu-toggle {
    background: transparent;
    border: 0;
    float: right;
    width: 40px;
    height: 28px;
    position: relative;
    margin: 34px 30px 5px 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
    z-index:1001;
    span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      background: $primary-color;
      border-radius: 9px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;
    }
    span:nth-child(1) {
      top: 0;
    }

    span:nth-child(2) {
      top: 10px;
    }

    span:nth-child(3) {
      top: 20px;
    }
  }
  .menu-toggle.open span:nth-child(1) {
    top: 10px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
    background:$font-color;
  }

  .menu-toggle.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
  }

  .menu-toggle.open span:nth-child(3) {
    top: 10px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
    background:$font-color;
  }

  header {
    height:200px;
    .title {
      margin-left:0;
    }
    .container {
      height:200px;
      h1 {
        font-size:36px;
      }
      h2 {
        font-size:17px;
      }
    }
  }

  .content-block {
    padding:30px 0 15px 0;
  }

  #opdrachtgevers {
    padding:30px 0;
  }
}