@media only screen and (max-width: 991px) {
  body {
    padding-top:140px;
  }
  .column-small,
  .column-medium {
    width:50%;
  }
  .column-large {
    width:60%;
  }
  .column-square {
    width:40%;
  }
  #quote_row_1,
  #quote_row_2 {
    width:100%;
  }
  #home-blocks {
    padding:30px 0;
    height:auto;
  }
}
@media screen and (max-width: 991px) and (min-width: 768px) {
  .navbar-fixed-top {
    min-height:140px;
    .container {
      width:100%;
      .brand {
        max-width:180px;
      }
      #navbar {
        .navbar-right {
          margin-top:65px;
          li {
            a {
              font-size:17px;
              margin:0 15px;
            }
          }
        }
      }
    }
    &.smaller {
      min-height:108px;
      .container {
        .brand {
          margin-top:24px;
        }
        #navbar {
          .navbar-right {
            margin-top:45px;
          }
        }
      }
    }
  }
}