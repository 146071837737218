header {
  width:100%;
  height:400px;
  position:relative;
  display:block;
  overflow:hidden;
  z-index:2;
  .container {
    position:relative;
    height:400px;
  }
  .title {
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    width:100%;
    z-index:10;
    padding-left:15px;
    margin-left:16.6666667%;
    font-family: $font-primary;
  }
  h1 {
    margin:0 0 10px 0;
    padding:0;
    position:relative;
    text-align:left;
    color:white;
    font-size:54px;
    font-weight:700;
  }
  h2 {
    margin:0;
    padding:0;
    color:white;
    font-size:18px;
    font-weight:300;
    letter-spacing:1px;
    text-transform:uppercase;
  }
  #bg-image {
    width:100%;
    height:100%;
    display:block;
    position:absolute;
    top:0;
    left:0;
    z-index:0;
    &:before {
      content:"";
      width:100%;
      height:100%;
      background:rgba(0,0,0,0.10);
      position:absolute;
      top:0;
      left:0;
      z-index:4;
    }
    &:after {
      content:"";
      position:absolute;
      width:100%;
      height:100%;
      opacity:0.3;
      background: rgb(0,0,0); /* Old browsers */
      z-index:3;
    }
  }
  &.no-overlay {
    #bg-image {
      &:after {
        width:0;
        height:0;
      }
    }
  }
}