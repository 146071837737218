@media only screen and (max-width: 1199px) {
  body {
    padding-top: 170px;
    &:before,
    &:after {
      background-size: 180px 273px;
    }
  }
  .navbar-fixed-top {
    min-height: 170px;
    .brand {
      max-width: 265px;
      img {
        height: auto !important;
      }
    }
    #navbar {
      .navbar-right {
        margin-top: 80px;
        li {
          a {
            font-size: 19px;
          }
        }
      }
    }

  }
  header {
    height: 320px;
    .container {
      height: 320px;
    }
    .title {
      margin-left: 8.33333333%;
    }
  }

  .content-block {
    .content {
      p, ul, li, ol {
        line-height: 30px;
        font-size: 16px;
      }
    }
  }

  #contact {
    #contact-block {
      font-size: 16px;
      line-height: 30px;
      .info {
        line-height: 30px;
      }
    }
  }
}

@media screen and (max-width: 1199px) and (min-width: 992px) {
  .navbar-fixed-top {
    &.smaller {
      min-height: 100px;
      .brand {
        max-width: 180px;
        margin-top: 20px;
        height: auto !important;
        img {
          height: auto !important;
        }
      }
      #navbar {
        .navbar-right {
          margin-top: 43px;
        }
      }
      &:before {
        height: 5px;
      }
    }
  }
}